import { IUserType } from "@urbancarecommunity/commons/@types";
import { NavigationGuard } from "vue-router";
import { authServiceInstance } from "..";

export const PublicAuthGuard: NavigationGuard = (to, from, next) => {
  const user = authServiceInstance.getAuthenticatedUser();

  if (user?.userType === IUserType.CARER) {
    return next("/carer");
  } else if (user?.userType === IUserType.CLIENT) {
    if (user.serviceUserId) {
      return next("/service-user");
    }
    return next("/client");
  }

  next();
};
